import React, { useState } from 'react';
import './OurPackagesStyles.css';
import PackageOverview from './PackageOverview';
import ScrollAnimation from '../../ScrollAnimation';

const OurPackages: React.FC = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDetails = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <section className='ourPackagesSection'>
            <h2 className='packageSectionHeading'>Выберите Роды Вашей Мечты</h2>
            <div className='packagesContainer'>

                <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                    <PackageOverview type="mini-just-birth" />
                </ScrollAnimation>
                <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                    <PackageOverview type="mini-birth-before" />
                </ScrollAnimation>
                <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                    <PackageOverview type="mini-birth-after" />
                </ScrollAnimation>

            </div>


            <div className='packagesContainer'>

                <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                    <PackageOverview type="already-here" />
                </ScrollAnimation>
                <ScrollAnimation direction={'down'} displayedThreshold={0.1} customClassName='replace-slide-animation'>
                    <PackageOverview type="standard" />
                </ScrollAnimation>
                <ScrollAnimation direction={'down'} displayedThreshold={0.3} customClassName='replace-slide-animation'>
                    <PackageOverview type="premium" />
                </ScrollAnimation>

                {/* <ScrollAnimation direction={'down'} displayedThreshold={0.4} customClassName='replace-slide-animation'>
                    <PackageOverview type="vip" />
                </ScrollAnimation> */}

            </div>

            <div className='packagesContainer'>
                <ScrollAnimation direction={'down'} displayedThreshold={0.3} customClassName='replace-slide-animation'>
                    <PackageOverview type="full" />
                </ScrollAnimation>
            </div>

            {/* <div className='accompanyingCallToAction'>Узнать подробнее</div> */}
        </section>
    );
};

export default OurPackages;