import React from 'react';
import './TechnicalFooterStyles.css'
import { ReactComponent as HojaRojaLogo } from '../../assets/temp/HojaRoja_logo.svg'
import { ReactComponent as HojaRojaLogoText } from '../../assets/temp/HojaRoja_logo_text.svg'

const TechnicalFooter: React.FC = () => {
    return (
        <div className='technical-footer-section'>


            <div className='text-about-site-container'>
                <div>© 2024 «Роды в Аргентине с Ольгой» - Заботливое сопровождение родов в Аргентине</div>
                <br />
                <div>Полное или частичное копирование материалов, размещённых на этом сайте, возможно только с письменного разрешения.</div>
            </div>
            {/* <div className='hojaroja-logo-container'>
                <div className='developed-by-text'>Desarollado por</div>
                <HojaRojaLogo className='hojaroja-logo' />
                <HojaRojaLogoText className='hojaroja-logo-text' />
            </div> */}
            <div className='build-id'>build: 6d8c1f</div>
        </div>
    );
};

export default TechnicalFooter;